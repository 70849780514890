/** @jsx jsx */
import { jsx } from "theme-ui"
// eslint-disable-next-line no-unused-vars
import React, { useEffect, useContext } from "react"
import { graphql } from "gatsby"

import HeaderContext from "../context/header-context"
import MotionWrapper from "../components/motion-wrapper"
import Container from "../components/container"
import Slab from "../components/slab"
import LinkList from "../components/link-list"
import Heading from "../components/heading"
import SEO from "../components/seo"

const TeamPage = ({ data }) => {
  const { departments } = data.craft

  const { updateHeaderState } = useContext(HeaderContext)

  useEffect(() => {
    updateHeaderState({
      section: "Team",
      menuLinkHoverColor: "accent",
    })
  }, [updateHeaderState])

  return (
    <>
      <SEO description="Meet the Mutual team" title="Team" />
      <MotionWrapper>
        <Slab first bg="primary" color="background">
          <Container>
            {departments.map(department => (
              <>
                <Heading
                  headingSx={{
                    marginBottom: 1,
                    color: "text",
                  }}
                  fullOpacity
                >
                  {department.title}
                </Heading>
                <LinkList
                  links={department.teamMembers}
                  parentPrefix="/is"
                  listSx={{ mb: 2 }}
                />
              </>
            ))}
          </Container>
        </Slab>
      </MotionWrapper>
    </>
  )
}

export const TeamPageQuery = graphql`
  {
    craft {
      departments: entries(section: "team", level: 1) {
        title
        slug
        teamMembers: children {
          title
          slug
        }
      }
    }
  }
`

export default TeamPage
